import Model, { attr, belongsTo } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default class ChairModel extends Model {
  @attr('number') position;

  @belongsTo('table', { async: false }) table;
  @belongsTo('participant', { async: false }) participant;

  @alias('participant.person') person;
}
