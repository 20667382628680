import Model, { attr, hasMany } from '@ember-data/model';

export default class EventModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('string') category;
  @attr('date') startDate;
  @attr('date') endDate;

  @hasMany('room', { async: false }) rooms;
  @hasMany('subEvent', { async: false }) subEvents;
  @hasMany('participant', { async: false }) participants;
}
