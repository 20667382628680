define("tables/components/scrollbar", ["exports", "@weareevermore/satellite/components/scrollbar"], function (_exports, _scrollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scrollbar.default;
    }
  });
});
