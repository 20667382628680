import Component from '@ember/component';
import { computed } from '@ember/object';

const OFFSET = 6;
const SIZE = 32;

export default class RoomVerticalTableComponent extends Component {
  tagName = '';

  width = 104;

  @computed('table.chairs.[]')
  get height() {
    return this.table.chairs.length > 3
      ? OFFSET * 2 + SIZE * Math.ceil(this.table.chairs.length / 2)
      : 72;
  }

  @computed('table.chairs.@each.{position,participant}')
  get chairs() {
    let chairsCount = this.table.chairs.length;

    return this.table.chairs.map((chair) => {
      let x = chair.position < chairsCount / 2 ? 0 : 76;
      let y = OFFSET + (chair.position % (chairsCount / 2)) * SIZE;

      return { x, y, id: chair.id, chair };
    });
  }
}
