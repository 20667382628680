import Route from '@ember/routing/route';

export default class RoomsShowEditRoute extends Route {
  async model() {
    let { room } = this.modelFor('rooms.show');
    let event = await this.store.findRecord('event', room.event.id, { include: 'sub-events', reload: true });

    return {
      room,
      event,
      subEvents: event.subEvents
    };
  }
}
