define("tables/components/nav", ["exports", "@weareevermore/satellite/components/nav"], function (_exports, _nav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _nav.default;
    }
  });
});
