import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { captureException } from '@sentry/browser';

export default class RoomsShowEditController extends Controller {
  @service('dialog') dialog;
  @service('notify') notify;
  @service('router') router;

  @alias('model.room') room;
  @alias('model.event') event;
  @alias('model.subEvents') subEvents;

  @task({ drop: true })
  saveTask = function * (attrs) {
    this.room.setProperties(attrs);

    try {
      yield this.room.save();

      this.notify.success('Room was updated successfully.');
      this.router.transitionTo('rooms.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }

  @task({ drop: true })
  deleteTask = function * () {
    yield this.dialog.confirmDelete();

    try {
      yield this.room.destroyRecord();

      this.notify.success('Room was deleted successfully.');
      this.router.transitionTo('rooms.list', this.event.id);
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }
}
