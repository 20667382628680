import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { captureException } from '@sentry/browser';

export default class RoomsListNewController extends Controller {
  @service('notify') notify;
  @service('router') router;
  @service('store') store;

  @alias('model') event;

  @task({ drop: true })
  saveTask = function * (attrs) {
    let room = this.store.createRecord('room', { event: this.event, ...attrs });

    try {
      yield room.save();

      this.notify.success('Room was created successfully.');
      this.router.transitionTo('rooms.show', this.event.id, room.id);
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }
}
