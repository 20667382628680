import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { lastValue } from 'ember-concurrency-decorators';

export default class EventsController extends Controller {
  queryParams = ['term', 'page'];
  page = 1;
  term = '';

  @alias('model.task') task;
  @alias('model.task.isRunning') isLoading;
  @lastValue('model.task') events;

  @action
  filter(term) {
    this.setProperties({ term, page: 1 });
  }
}
