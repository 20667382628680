import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export const TABLE_TYPES = ['round', 'horizontal', 'vertical'];
export const TABLE_TYPE_LABELS = ['Round', 'Horizontal', 'Vertical'];
export const TABLE_TYPES_FORM_COLLECTION = TABLE_TYPES.map((value, i) => ({ value, label: TABLE_TYPE_LABELS[i] }));

export default class TableModel extends Model {
  @attr('string') tableType;
  @attr('string') name;
  @attr('number') numberOfChairs;
  @attr('number') x;
  @attr('number') y;

  @belongsTo('room', { async: false }) room;
  @hasMany('chair', { async: false }) chairs;
}
