define("tables/components/datepicker-field", ["exports", "@weareevermore/satellite/components/datepicker-field"], function (_exports, _datepickerField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _datepickerField.default;
    }
  });
});
