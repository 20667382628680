import Component from '@ember/component';
import { action, computed, get } from '@ember/object';

export default class ParticipantsDrawerComponent extends Component {
  tagName = '';
  areFiltersVisible = false;
  term = '';
  filter = 'all';

  init() {
    super.init();

    if (this.activeTable) {
      this.areFiltersVisible = true;
      this.filter = 'table';
    }
  }

  get activeTable() {
    return this._activeTable;
  }

  set activeTable(table) {
    if (this.filter === 'table' && !table) {
      this.set('filter', 'all');
    }

    this._activeTable = table;
  }

  @computed('participants.@each.{table,fullName,lastName}', 'term', 'filter', 'activeTable.id')
  get _participants() {
    let participants = this.participants;
    let term = [];
    let tableName = '';

    this.term.split(' ').forEach((segment) => {
      if (segment.indexOf('@') === 0) {
        if (segment.indexOf('@table:') === 0) {
          tableName = segment.replace('@table:', '');
        }
      } else {
        term.push(segment);
      }
    });

    if (term.length) {
      term = term.join(' ').toLowerCase().trim();
      participants = participants.filter(({ fullName }) => {
        return fullName.toLowerCase().indexOf(term) > -1;
      });
    }

    if (this.filter === 'seated') {
      participants = participants.filter((participant) => get(participant, 'table'));
    }

    if (this.filter === 'not-seated') {
      participants = participants.filter((participant) => !get(participant, 'table'));
    }

    if (this.filter === 'accepted') {
      participants = participants.filter(({ accepted }) => accepted);
    }

    if (this.filter === 'table' && this.activeTable) {
      participants = participants.filter((participant) => get(participant, 'table.id') == this.activeTable.id);
    }

    if (tableName) {
      participants = participants.filter((participant) => get(participant, 'table.name') == tableName);
    }

    return participants.sortBy('lastName');
  }

  @action toggleFilters() {
    if (this.areFiltersVisible) this.clearFilters();
    this.set('areFiltersVisible', !this.areFiltersVisible);
  }

  @action clearFilters() {
    this.set('term', '');
    this.set('filter', 'all');
  }
}
