define("tables/components/shuttle-select/create-option", ["exports", "@weareevermore/shuttle/components/shuttle-select/create-option"], function (_exports, _createOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _createOption.default;
    }
  });
});
