import EmberRouter from '@ember/routing/router';
import config from './config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL
});

Router.map(function () {
  this.route('auth-callback');

  this.route('home', { path: '' }, function () {
    this.route('events', { path: '', resetNamespace: true }, function () {
      this.route('list', { path: '' });
    });

    this.route('rooms', { path: 'event', resetNamespace: true }, function () {
      this.route('list', { path: ':event_id/rooms' }, function () {
        this.route('new');
      });

      this.route('show', { path: ':event_id/rooms/:room_id' }, function () {
        this.route('edit');

        this.route('tables', function () {
          this.route('new');
          this.route('edit', { path: ':table_id/edit' });
        });

        this.route('participants');
      });
    });
  });

  this.route('not-found', { path: '/*dummy' });
});

export default Router;
