import Component from '@ember/component';
import { action } from '@ember/object';
import { TABLE_TYPES_FORM_COLLECTION } from 'tables/models/table';

export default class TableFormComponent extends Component {
  tableTypes = TABLE_TYPES_FORM_COLLECTION;

  init() {
    super.init(...arguments);

    this.model = {
      tableType: this.table.tableType,
      name: this.table.name,
      numberOfChairs: this.table.numberOfChairs
    };
  }

  @action
  save() {
    this.onSave(this.model);
  }
}
