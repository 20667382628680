import Component from '@ember/component';
import { action } from '@ember/object';

export default class RoomFormComponent extends Component {
  init() {
    super.init(...arguments);

    this.model = {
      subEvent: this.room.subEvent,
      name: this.room.name,
      width: this.room.width,
      length: this.room.length
    };
  }

  @action
  save() {
    this.onSave(this.model);
  }
}
