import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ApplicationRouteMixin from '@weareevermore/wjc-auth/mixins/application-route';
import Sentry from '@sentry/browser';

export default class ApplicationRoute extends Route.extend(ApplicationRouteMixin) {
  @service dialog;
  @service session;

  afterModel() {
    this._setupSentryContext();
  }

  @action
  sessionAuthenticated() {
    this._setupSentryContext();
  }

  @action
  sessionInvalidated() {
    this.get('dialog').confirm({
      title: 'Session expired',
      message: 'Looks like your session has expired. Please, login to continue.',
      confirmLabel: 'Login'
    }).then(() => {
      this.send('login');
    });
  }

  _setupSentryContext() {
    if (!this.session.isAuthenticated) { return; }

    Sentry.setUser({
      id: this.session.user.id,
      email: this.session.user.email
    });
  }
}
