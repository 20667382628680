import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class TransitionToHelper extends Helper {
  @service('router') router;

  compute(params) {
    return (maybeEvent) => {
      if (maybeEvent !== undefined && typeof maybeEvent.preventDefault === 'function') {
        maybeEvent.preventDefault();
      }

      params = params.map((param) => {
        if (param && param.isQueryParams) {
          return { queryParams: param.values };
        }

        return param;
      });

      return this.router.transitionTo(...params);
    };
  }
}
