import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

export default class ParticipantModel extends Model {
  @attr('string') title;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') response;

  @belongsTo('event', { async: false }) event;
  @belongsTo('chair', { async: false }) chair;
  @belongsTo('shuttlePerson') person;

  @alias('chair.table') table;

  @computed('title', 'firstName', 'lastName')
  get fullName() {
    return [
      this.title,
      this.firstName,
      this.lastName
    ].filter((v) => v).join(' ');
  }

  @computed('response')
  get accepted() {
    return this.response === 'yes';
  }

  get personId() {
    return this.belongsTo('person').id();
  }
}
