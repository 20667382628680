define("tables/components/empty-state", ["exports", "@weareevermore/satellite/components/empty-state"], function (_exports, _emptyState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emptyState.default;
    }
  });
});
