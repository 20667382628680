import Route from '@ember/routing/route';

export default class RoomsListRoute extends Route {
  // eslint-disable-next-line camelcase
  model({ event_id }) {
    return this.store.findRecord('event', event_id, {
      include: 'rooms,rooms.sub-event,sub-events',
      reload: true
    });
  }

  afterModel(event) {
    this.set('title', event.name);
  }
}
