import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';

export default class RoomModel extends Model {
  @attr('string') name;
  @attr('number') width;
  @attr('number') length;

  @belongsTo('event', { async: false }) event;
  @belongsTo('subEvent', { async: false }) subEvent;
  @hasMany('table', { async: false }) tables;

  @computed('width', 'length')
  get size() {
    return this.width * this.length;
  }

  @computed('name', 'subEvent.name')
  get searchable() {
    let subEventName = this.subEvent ? this.subEvent.name : '';
    return (this.name + subEventName).toLowerCase();
  }
}
