import Component from '@ember/component';
import { action, computed } from '@ember/object';

export default class RoomChairComponent extends Component {
  tagName = '';

  @computed('participant.{firstName,lastName}')
  get label() {
    if (this.participant) {
      let { firstName, lastName } = this.participant;
      return this.getFirstLetter(firstName) + this.getFirstLetter(lastName);
    } else {
      return '';
    }
  }

  getFirstLetter(value) {
    return value ? value.substring(0, 1) : '';
  }

  @action preventDefault(e) {
    e.preventDefault();
  }
}
