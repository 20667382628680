import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'tables/config/environment';

export default class ExportButtonComponent extends Component {
  tagName = '';

  @service('session') session;

  get action() {
    return ENV.GUM_HOST + '/api/jb2/export/room.csv';
  }

  @computed('room.id', 'session.token')
  get data() {
    return JSON.stringify({
      token: this.session.token,
      room_id: this.room.id
    });
  }
}
