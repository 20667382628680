define("tables/components/shuttle-person/relation-form", ["exports", "@weareevermore/shuttle/components/shuttle-person/relation-form"], function (_exports, _relationForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _relationForm.default;
    }
  });
});
