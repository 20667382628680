define("tables/components/fieldset", ["exports", "@weareevermore/satellite/components/fieldset"], function (_exports, _fieldset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fieldset.default;
    }
  });
});
