import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ParticipantItemComponent extends Component {
  @service('shuttle-widget') shuttleWidget;

  tagName = '';

  @action onDragStart(e) {
    e.dataTransfer.setData('participantId', this.participant.id);
  }

  @action openShuttleWidget() {
    this.shuttleWidget.open('person', {
      personId: this.participant.personId
    });
  }
}
