import Component from '@ember/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { roundDouble } from './utils';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';

export default class RoomComponent extends Component {
  tagName = '';

  scale = 1;
  x = 24;
  y = 24;

  @action enableMove() {
    this.shouldMove = true;
    document.body.classList.add('disable-user-select');
  }

  resetMove() {
    this.shouldMove = false;
    this.moveTable = null;
    this.isTableMoved = false;
  }

  @action onMouseMove(e) {
    if (!this.shouldMove) return;

    if (this.moveTable) {
      this.isTableMoved = true;
      this.moveTable.set('y', this.moveTable.y + e.movementY / this.scale);
      this.moveTable.set('x', this.moveTable.x + e.movementX / this.scale);

    } else {
      this.set('y', this.y + e.movementY);
      this.set('x', this.x + e.movementX);
    }
  }

  @action onMouseUp() {
    if (this.moveTable && this.isTableMoved) {
      this.moveTable.set('x', Math.round(this.moveTable.x));
      this.moveTable.set('y', Math.round(this.moveTable.y));
      this.onTableUpdate(this.moveTable);
    }

    this.resetMove();
    document.body.classList.remove('disable-user-select');
  }

  @action onTableMouseDown(table) {
    this.moveTable = table;
    this.onTableSelect(table);
  }

  @action zoomIn() {
    let nextScale = roundDouble(this.scale * 1.1);
    let difference = (nextScale - this.scale) / 2;

    this.set('scale', nextScale);
    this.set('x', this.x - Math.round((this.room.width * 40) * difference));
    this.set('y', this.y - Math.round((this.room.length * 40) * difference));
  }

  @action zoomOut() {
    let nextScale = roundDouble(this.scale / 1.1);
    let difference = (this.scale - nextScale) / 2;

    this.set('scale', nextScale);
    this.set('x', this.x + Math.round((this.room.width * 40) * difference));
    this.set('y', this.y + Math.round((this.room.length * 40) * difference));
  }

  @action focus() {
    this.roomElement = this.roomElement || document.getElementById('room');

    let parentRatio = this.roomElement.offsetWidth / this.roomElement.offsetHeight;
    let roomRatio = this.room.width / this.room.length;
    let widthScale = (this.roomElement.offsetWidth - 24) / (this.room.width * 40);
    let heightScale = (this.roomElement.offsetHeight - 24) / (this.room.length * 40);
    let scale = roundDouble(parentRatio < roomRatio ? widthScale : heightScale);

    this.set('scale', scale);
    this.set('x', Math.round(this.roomElement.offsetWidth / 2 - this.room.width * 20 * scale));
    this.set('y', Math.round(this.roomElement.offsetHeight / 2 - this.room.length * 20 * scale));
  }

  @action attachDocumentEvents() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  @action detachDocumentEvents() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  @task({ restartable: true })
  toggleTooltipTask = function * (participant, e) {
    yield timeout(200);

    if (participant && !this.moveTable) {
      let bounds = e.target.getBoundingClientRect();
      let top = bounds.top - 52;
      let left = bounds.left + bounds.width / 2;

      this.set('tooltip', {
        label: participant.fullName,
        style: htmlSafe(`top: ${top}px; left: ${left}px;`)
      });
    } else {
      this.set('tooltip', null);
    }
  }
}
