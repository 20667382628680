define("tables/models/shuttle-tag", ["exports", "@weareevermore/shuttle/models/shuttle-tag"], function (_exports, _shuttleTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _shuttleTag.default;
    }
  });
});
