import Route from '@ember/routing/route';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';

export default class EventsRoute extends Route {
  title = 'Events';

  queryParams = {
    term: { refreshModel: true },
    page: { refreshModel: true }
  };

  @task({ restartable: true })
  loadTask = function * ({ term, page }) {
    let query = {
      filter: {},
      page: { number: page, size: 10 },
      sort: '-start-date'
    };

    if (term) {
      yield timeout(200);
      query.filter.term = term;
    }

    return this.store.query('event', query);
  }

  model(params) {
    this.loadTask.perform(params);

    return { task: this.loadTask };
  }
}
