import Route from '@ember/routing/route';

export default class RoomsShowRoute extends Route {
  // eslint-disable-next-line camelcase
  async model({ room_id }) {
    let room = await this.store.findRecord('room', room_id, { include: 'event,sub-event,tables.chairs.participant', reload: true });
    let participants = await this.store.query('participant', { filter: this.getParticipantsFilter(room) });

    return { room, participants };
  }

  afterModel({ room }) {
    this.set('crumbs', [
      { title: room.event.name, route: 'rooms.list', models: [room.event.id] },
      { title: room.name, route: 'rooms.show', models: [room.event.id, room.id] }
    ]);
  }

  getParticipantsFilter(room) {
    let eventId = room.belongsTo('event').id();
    let subEventId = room.belongsTo('subEvent').id();
    return subEventId ? { 'sub-event': subEventId } : { event: eventId };
  }
}
