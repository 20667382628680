import Component from '@ember/component';
import { computed } from '@ember/object';
import { roundDouble } from '../utils';

const CHAIR = 28;
const OFFSET = 6;

export default class RoomTableComponent extends Component {
  tagName = '';

  @computed('table.chairs.[]')
  get radius() {
    let chairs = this.table.chairs.length < 5 ? 5 : this.table.chairs.length;
    let radius = chairs * CHAIR / Math.PI / 2;
    return Math.round(radius);
  }

  @computed('radius')
  get width() {
    return this.radius * 2 + 40;
  }

  @computed('table.chairs.@each.{position,participant}')
  get chairs() {
    return this.table.chairs.map((chair) => {
      let radians = (Math.PI * 2) * (chair.position / this.table.chairs.length);
      let x = Math.cos(radians) * (this.radius + OFFSET) + this.radius + OFFSET;
      let y = Math.sin(radians) * (this.radius + OFFSET) + this.radius + OFFSET;

      return { x: roundDouble(x), y: roundDouble(y), id: chair.id, chair };
    });
  }
}
