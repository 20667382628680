import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { captureException } from '@sentry/browser';

export default class TablesNewController extends Controller {
  @service('notify') notify;
  @service('router') router;
  @service('store') store;

  @alias('model') room;

  @task({ drop: true })
  saveTask = function * (attrs) {
    let table = this.store.createRecord('table', { room: this.room, ...attrs });

    try {
      yield table.save();

      this.notify.success('Table was created successfully.');
      this.router.transitionTo('rooms.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }
}
