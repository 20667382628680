import Component from '@ember/component';
import { computed } from '@ember/object';

export default class RoomActionsComponent extends Component {
  tagName = '';

  @computed('scale')
  get label() {
    return Math.round(this.scale * 100) + '%';
  }
}
