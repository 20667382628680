define("tables/helpers/find-index-of", ["exports", "@weareevermore/shuttle/helpers/find-index-of"], function (_exports, _findIndexOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _findIndexOf.default;
    }
  });
  Object.defineProperty(_exports, "findIndexOf", {
    enumerable: true,
    get: function get() {
      return _findIndexOf.findIndexOf;
    }
  });
});
