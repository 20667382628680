import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { captureException } from '@sentry/browser';

export default class RoomsListController extends Controller {
  queryParams = ['term'];
  term = null;

  @service('dialog') dialog;
  @service('notify') notify;

  @alias('model') event;

  @computed('event.rooms.@each.searchable', 'term')
  get rooms() {
    let rooms = this.event.rooms.toArray();

    if (this.term) {
      let term = this.term.toLowerCase();
      rooms = rooms.filter((room) => room.searchable.indexOf(term) > -1);
    }

    return rooms.sort((a, b) => a.name > b.name ? 1 : -1);
  }

  @computed('event.rooms.[]', 'term')
  get isEmptyStateVisible() {
    return !this.term && this.event.rooms.length === 0;
  }

  @task({ drop: true })
  deleteRoomTask = function * (room) {
    try { yield this.dialog.confirmDelete(); } catch (e) { return; }

    try {
      yield room.destroyRecord();
      this.notify.success('Room was deleted successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }
}
