define("tables/components/shuttle-person/membership", ["exports", "@weareevermore/shuttle/components/shuttle-person/membership"], function (_exports, _membership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _membership.default;
    }
  });
});
