define("tables/services/auth-ajax", ["exports", "@weareevermore/wjc-auth/services/auth-ajax"], function (_exports, _authAjax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _authAjax.default;
    }
  });
});
