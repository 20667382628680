import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { captureException } from '@sentry/browser';

export default class RoomsShowController extends Controller {
  @service('dialog') dialog;
  @service('notify') notify;
  @service('store') store;

  queryParams = ['drawer', 'table'];
  drawer = null;
  table = null;

  @alias('model.room') room;
  @alias('room.event') event;
  @alias('room.subEvent') subEvent;
  @alias('model.participants') participants;

  @computed('table')
  get activeTable() {
    return this.room.tables.find(({ id }) => id === this.table);
  }

  @task({ drop: true })
  updateTableTask = function * (table) {
    try {
      yield table.save();
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong while trying to update the table. Please, try again later.');
    }
  }

  @task({ drop: true })
  deleteTableTask = function * (table) {
    yield this.dialog.confirmDelete();

    try {
      yield table.destroyRecord();
      this.set('table', null);
      this.notify.success('Table was deleted successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }

  @task({ drop: true })
  seatParticipantTask = function * (chair, dropEvent) {
    let id = dropEvent.dataTransfer.getData('participantId');
    let participant = this.store.peekRecord('participant', id);
    chair.set('participant', participant);

    try {
      yield chair.save();
      this.notify.success('Participant was seated successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }

  @task({ drop: true })
  unseatParticipantTask = function * (chair) {
    chair.set('participant', null);

    try {
      yield chair.save();
      this.notify.success('Participant removed from the chair.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }

  @action transitionToTable(table) {
    this.transitionToRoute('rooms.show', { queryParams: { table: table.id } });
  }
}
