import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { captureException } from '@sentry/browser';

export default class TablesEditController extends Controller {
  @service('dialog') dialog;
  @service('notify') notify;
  @service('router') router;

  @alias('model') table;

  @task({ drop: true })
  saveTask = function * (attrs) {
    this.table.setProperties(attrs);

    try {
      yield this.table.save();

      this.notify.success('Table was updated successfully.');
      this.router.transitionTo('rooms.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }

  @task({ drop: true })
  deleteTask = function * () {
    yield this.dialog.confirmDelete();

    try {
      yield this.table.destroyRecord();

      this.notify.success('Table was deleted successfully.');
      this.router.transitionTo('rooms.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }
}
