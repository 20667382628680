define("tables/components/error-page/500", ["exports", "@weareevermore/satellite/components/error-page/500"], function (_exports, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _.default;
    }
  });
});
